import React, { lazy, Suspense } from "react";
import { graphql } from "gatsby";

const Stats = lazy(() => import("../modules/stats"));

const statsPage = () => (
  <>
    <Suspense fallback={<div>Loading...</div>}>
      {typeof window === "object" ? <Stats /> : null}
    </Suspense>
  </>
);

export default statsPage;
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      nodes {
        data
        ns
        language
      }
    }
  }
`;
